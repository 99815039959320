
@font-face {
    font-family: 'Museo 300';
    font-style: normal;
    font-weight: normal;
    src: local('Museo 300'), url('./fonts/Museo300-Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Museo 700';
    font-style: normal;
    font-weight: normal;
    src: local('Museo 700'), url('./fonts/Museo700-Regular.woff') format('woff');
    }

html, body, #root{
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./img/map.jpg");
  background-size: cover;
  background-position: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main{
	height: 100%;
	width: 100%;
	display:flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.flag-icon{
	width: 50px !important;
	height: 50px;
}

.btn-primary{
	background-color: black !important;
	border-color: black !important;
	font-family: "Museo 700" !important;
}

@media screen and (max-width: 1024px){
    .main .w-50{
		width: 80% !important;
    }
}